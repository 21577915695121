import { useToast } from 'vue-toastification';
import { reactive, defineComponent } from "vue";
import { ILoginRequest } from "../../models/ILoginRequest";
import AuthService from "../../services/Auth/AuthService";
import { authStore } from '../../store/modules/auth';
import { useRouter } from "vue-router";
import {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxRequiredRule,
  DxEmailRule,
} from "devextreme-vue/ui/form";
import { DxLoadPanel } from 'devextreme-vue/ui/load-panel';

export default defineComponent({
  name: "Login",
  setup() {
    const toast = useToast()
    const store = authStore();
    const router = useRouter();
    const state = reactive({
      model: {} as ILoginRequest,
      isLoading: false,
      logo: require('../../assets/img/logo-green.svg')
    })

    async function LoginAsync() {
      state.isLoading = true;

      try {
        const response = await AuthService.Login(state.model);
        if (response.status === 200) {
          store.LOGIN_SUCCESS(response.data);
          router.push({ path: '/clientes' });
          state.isLoading = false;
        }
        else {
          toast.error(response.request.responseText);
        }
        state.isLoading = false;
      } catch (error: any) {  
        toast.error(error.response? error.response.data : error.message);
        state.isLoading = false;  
      }
    }

    return {
      state,
      LoginAsync
    };
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxRequiredRule,
    DxEmailRule,
    DxLoadPanel,
  },
});