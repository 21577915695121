<template>
  <div id="app-login">
    <header>
      <h3>Portal do Contador</h3>
    </header>
    <main>
      <div class="card-form">
        <img :src="state.logo" alt="Logomarca" />
        <h5>Acesso ao Portal</h5>
        <form method="post" @submit.prevent="LoginAsync">
          <DxForm class="w-full" :form-data="state.model">
            <DxSimpleItem
              data-field="Email"
              :editor-options="{ mode: 'email' }"
            >
              <DxRequiredRule />
              <DxEmailRule />
            </DxSimpleItem>
            <DxSimpleItem
              data-field="Senha"
              :editor-options="{ mode: 'password' }"
            >
              <DxRequiredRule />
            </DxSimpleItem>
            <DxButtonItem
              :button-options="{
                text: 'Entrar',
                type: 'default',
                useSubmitBehavior: true,
                width: 300,
                height: 50,
              }"
            ></DxButtonItem>
          </DxForm>
        </form>
      </div>
    </main>
  </div>
  <DxLoadPanel
    :visible="state.isLoading"
    :show-pane="true"
    :shading="true"
    shading-color="rgba(255, 255, 255, 0.8)"
  />
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="postcss" scoped>
#app-login {
  @apply w-full h-full flex justify-center items-center bg-brand-back;
}
header {
  @apply w-full h-1/6 top-0 absolute flex justify-center items-center bg-brand-accent;
}
header h3 {
  @apply text-center text-white text-4xl font-thin;
}
.card-form {
  @apply flex flex-col justify-between items-center;
  width: 300px;
  height: calc(100% - 16.666667%);
}
h5 {
  @apply text-gray-600 font-thin mt-2;
}
.div-loading {
  @apply w-full h-full fixed top-0 bg-gray-900 opacity-25 flex justify-center items-center;
}

</style>